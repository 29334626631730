<template>
   <AmcText class="shadow-sm p-4 rounded-8">
      <header class="d-flex justify-content-between">
         <slot name="header">
            <AmcText></AmcText>
         </slot>
         <AmcText class="align-self-center">
            <AmcPagination v-model="currentPage" :data="data" />
         </AmcText>
      </header>
      <AmcText
         :class="[
            hover && 'table-hover',
            striped && 'table-striped',
            bordered && 'table-bordered',
            borderless && 'table-borderless',
         ]"
         as="table"
         class="amc-table table"
      >
         <AmcText as="thead">
            <AmcText as="tr">
               <AmcText
                  v-for="(col, index) in header"
                  :key="index"
                  as="th"
                  class="text-uppercase text-secondary text-start"
                  :class="{ 'center-item': Center }"
                  v-html="$t(`tableTitle.${col.title}`)"
               >
                  <slot #title></slot>
               </AmcText>
            </AmcText>
         </AmcText>
         <AmcText as="tbody" v-if="dataCount > 0">
            <AmcText
               as="tr"
               v-for="row in data.data"
               :key="row.id"
               :class="{
                  'bg-online': row.state === 'Online',
                  'bg-alarm': row.state === 'Alarm',
                  'bg-fault': row.state === 'Arıza',
               }"
            >
               <AmcText
                  @click="$emit('detail', row)"
                  as="td"
                  v-for="(col, index) in header"
                  :key="index"
                  :class="{ 'table-border': Border }"
                  class="table-item py-3"
                  role="button"
               >
                  <slot :name="col.value" v-bind:prop="get(row, col.value)" v-bind:item="row">
                     <AmcText v-html="get(row, col.value)" v-if="get(row, col.value)" />
                  </slot>
               </AmcText>

               <AmcText class="option d-flex justify-content-end pe-3" v-if="options">
                  <AmcText>
                     <button class="remove-icon" @click="showRemoveModal(row)">
                        <IconDel class="my-2" />
                     </button>
                  </AmcText>
                  <AmcText>
                     <button @click="$emit('detail', row)" class="detail-icon ms-3">
                        <IconTextBox class="my-2" />
                     </button>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcText class="text-secondary d-flex flex-row justify-content-end">
         <AmcText class="d-flex flex-row gap-2">
            <AmcText class="d-flex flex-row">
               <AmcText fwBold class="text-secondary"> {{ $t('pegination.Sayfa Sıralaması') }} :</AmcText>
               <AmcText>
                  <select class="form-select" v-model="perPage">
                     <option value="5">5</option>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="20">20</option>
                     <option value="50">50</option>
                  </select>
               </AmcText>
            </AmcText>
            <AmcPagination v-model="currentPage" :data="data" />
         </AmcText>
      </AmcText>
      <AmcRemoveModal ref="deleteModal" @remove="(item) => $emit('remove', item)" />
   </AmcText>
</template>

<script>
import get from 'lodash/get';
import IconDel from '@/components/icons/IconDel';
import IconTextBox from '@/components/icons/IconTextBox';

export default {
   name: 'AmcTable',
   components: {
      IconDel,
      IconTextBox,
   },
   props: {
      header: {
         type: [Array, Object],
         default: () => {},
      },
      data: {
         type: Object,
         default: () => {},
      },
      searchInput: {
         type: Boolean,
         default: false,
      },
      addButton: {
         type: Boolean,
         default: false,
      },
      hover: {
         type: Boolean,
         default: false,
      },
      striped: {
         type: Boolean,
         default: false,
      },
      bordered: {
         type: Boolean,
         default: false,
      },
      borderless: {
         type: Boolean,
         default: true,
      },
      Border: {
         type: Boolean,
         default: false,
      },
      Center: {
         type: Boolean,
         default: false,
      },
      options: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         search: '',
         currentPage: 1,
         perPage: 10,
         tableInitialized: false,
      };
   },

   computed: {
      onlineCount() {
         if (!this.data.data) return;

         return this.data.data.filter((d) => d.status === 'online').length;
      },
      dataCount() {
         return get(this, 'data.meta.total');
      },
   },

   beforeMount() {
      this.currentPage = this.$route.query.page || this.currentPage;
      this.perPage = this.$route.query.perPage || this.perPage;
   },

   created() {
      if (this.tableInitialized) return;

      const { currentPage: page, perPage } = this;

      this.$emit('initTable', { page, perPage });
      this.tableInitialized = true;
   },
   beforeDestroy() {
      this.tableInitialized = false;
   },
   methods: {
      get,
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
   },
   watch: {
      currentPage(page) {
         if (this.$route.query.page === page) return;
         this.$router.push({ query: { ...this.$route.query, page } });
         this.$emit('pageChanged', page);
      },
      perPage(perPage) {
         if (this.$route.query.perPage === perPage) return;

         this.$router.push({ query: { ...this.$route.query, perPage } });
         this.$router.push({ query: { ...this.$route.query, page: '1' } }).catch(() => {});
         this.$emit('perPageChanged', perPage);
      },
      startDate(startDate) {
         if (this.$route.query.startDate === startDate) return;

         this.$router.push({ query: { ...this.$route.query, startDate } });
         this.$emit('startDate', startDate);
      },
      endDate(endDate) {
         if (this.$route.query.endDate === endDate) return;

         this.$router.push({ query: { ...this.$route.query, endDate } });
         this.$emit('endDate', endDate);
      },
   },
};
</script>

<style lang="scss" scoped>
.table {
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;

   & .center-item {
      &:nth-child(5) {
         text-align: center !important;
      }

      &:nth-child(6) {
         text-align: center !important;
      }

      &:nth-child(7) {
         text-align: center !important;
      }
   }

   & .table-border {
      &:nth-child(5) {
         border-left: 1px solid #ebebeb;
         border-right: 1px solid #ebebeb;
         text-align: center;
      }

      &:nth-child(6) {
         border-right: 1px solid #ebebeb;
         text-align: center;
      }

      &:nth-child(7) {
         border-right: 1px solid #ebebeb;
         text-align: center;
      }
   }

   & .table-item {
      font-weight: 400;
      color: #606f89;

      &:nth-child(1) {
         font-weight: 500 !important;
         color: #2e3b52;
      }
   }
}

.form-select {
   border: none !important;
   padding-top: 0;
   color: #606f89;
   font-weight: 500;

   &:focus {
      box-shadow: none !important;
   }
}

.bg-online {
   background-color: #d5eedc;
}

.bg-fault {
   background-color: #fdf2d1;
}

.bg-alarm {
   background-color: #fbddda;
}
.dropdown-menu {
   padding: 15px;
   border-radius: 10px;
   box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.075);
   & .dropdown-item:hover {
      background-color: transparent;
      color: #0d6efd;
      border: 1px solid #0d6efd;
      border-radius: 5px;
   }
   & .remove:hover {
      background-color: transparent;
      color: #ea3d2f;
      border: 1px solid #ea3d2f;
      border-radius: 5px;
   }
}
</style>
